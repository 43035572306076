import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Unlock: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M6.33332 9.16655V5.83322C6.33228 4.79992 6.71522 3.80311 7.40779 3.03627C8.10037 2.26944 9.05317 1.7873 10.0812 1.68346C11.1093 1.57961 12.1393 1.86147 12.9712 2.47431C13.8032 3.08715 14.3777 3.98726 14.5833 4.99988M4.66667 9.1666H16.3333C17.2538 9.1666 18 9.91279 18 10.8333V16.6666C18 17.5871 17.2538 18.3333 16.3333 18.3333H4.66667C3.74619 18.3333 3 17.5871 3 16.6666V10.8333C3 9.91279 3.74619 9.1666 4.66667 9.1666Z"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Unlock;
